<template>
  <validation-provider
    #default="{ errors }"
    name="bidding_is_filed"
    :rules="{
      required: requiredField,
    }"
  >
    <b-form-group
      :label="labelName"
      label-for="bidding_is_filed"
      :label-class="labelClass"
    >
      <v-select
        id="bidding_is_filed"
        v-model="filedStatus"
        :class="errors.length > 0 ? 'error-select' : ''"
        :options="filedStatusOptions"
        :placeholder="'Licitação Arquivada'"
        :disabled="readonly"
        :clearable="!readonly"
      >
        <template #option="{ label }">
          <span>{{ label }}</span>
        </template>

        <template #selected-option="{ label }">
          <span>{{ label }}</span>
        </template>

      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'filedStatusValue',
    event: 'filedStatusChange',
  },
  props: {
    filedStatusValue: {
      type: [Object, Boolean],
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    labelName: {
      type: String,
      required: false,
      default: 'Licitação Arquivada',
    },
    labelClass: {
      type: [String, Array],
      required: false,
      default: '',
    },
  },
  data() {
    const filedStatusOptions = [
      {
        label: 'Arquivadas',
        value: true,
      },
      {
        label: 'Não Arquivadas',
        value: false,
      },
      {
        label: 'Arquivadas & Não Arquivadas',
        value: null,
      },
    ]

    return {
      filedStatusOptions,
    }
  },
  computed: {
    filedStatus: {
      get() {
        return this.filedStatusValue
      },
      set(value) {
        const valueMap = {
          true: {
            label: 'Arquivadas',
            value: true,
          },
          false: {
            label: 'Não Arquivadas',
            value: false,
          },
          null: {
            label: 'Arquivadas & Não Arquivadas',
            value: null,
          },
        }

        this.$emit('filedStatusChange', valueMap[value] || value)
      },
    },
  },
  watch: {
    filedStatusValue(newVal) {
      this.filedStatus = newVal
    },
  },
  created() {
    this.$emit('ready', true)
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select {
  background-color: #fff;
}

.vs__search {
  font-size: 1rem !important;
}
</style>
