<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <bidding-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @viewmode-change="updateViewMode"
            @export-filter="onExportFilter"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        v-show="viewMode === viewModeTable"
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <bidding-table
            ref="refBiddingTable"
            :filter="filter"
            :active="viewMode === viewModeTable"
            @openLoading="openModalExportSpinner"
            @closeLoading="closeModalExportSpinner"
            @openSuccess="openModalExportSuccess"
            @openUnsuccess="openModalExportUnsuccess"
            @updateExportProgress="updateProgress"
            @finishExport="resetProgress"
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
      <b-row
        v-show="viewMode === viewModeKanban"
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <bidding-kanban
            ref="refBiddingKanban"
            :filter="filter"
            :active="viewMode === viewModeKanban"
            @ready="syncLoad.ready('kanban')"
          />
        </b-col>
      </b-row>
      <div>
        <b-button
          id="addBiddingBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :to="{ name: 'bidding-create'}"
          :disabled="!hasPermissionToWrite"
          title="Adicionar licitação"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
      <b-row>
        <div>
          <b-modal
            id="idModalExportSpinner"
            ref="modalExportSpinner"
            no-close-on-backdrop
            no-close-on-esc
            centered
            hide-footer
          >
            <b-form
              class="mt-1"
            >
              <b-row>
                <b-col>
                  <span
                    class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalLoading"
                  >
                    <svg-icon
                      type="mdi"
                      size="70"
                      :path="mdiFolderOpenOutline"
                    />
                  </span>
                  <span
                    class="d-flex justify-content-center px-2 fixLayoutTextModal"
                  >
                    Exportando Dados
                  </span>
                  <span
                    id="modalPercentage"
                    class="d-flex justify-content-center px-2 my-2"
                  >
                    {{ exportPercentage }}
                  </span>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
        <div>
          <b-modal
            id="idModalExportUnsuccess"
            ref="modalExportUnsuccess"
            class="modalStyle"
            no-close-on-backdrop
            no-close-on-esc
            centered
            hide-footer
          >
            <b-form
              class="mt-1"
            >
              <b-row>
                <b-col>
                  <span
                    class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalUnsuccess"
                  >
                    <svg-icon
                      type="mdi"
                      size="70"
                      :path="mdiContentSaveAlertOutline"
                    />
                  </span>
                  <span
                    class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
                  >
                    Falha ao exportar dados para o modelo!
                  </span>
                  <span
                    class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
                  >
                    <b-button
                      id="hide-btn"
                      class="modal-btn-fix"
                      variant="primary"
                      @click="closeModalExportUnsuccess()"
                    >
                      Ok
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
        <div>
          <b-modal
            id="idModalExportSuccess"
            ref="modalExportSuccess"
            class="modalStyle"
            no-close-on-backdrop
            no-close-on-esc
            centered
            hide-footer
          >
            <b-form
              class="mt-1"
            >
              <b-row>
                <b-col>
                  <span
                    class="d-flex justify-content-center px-2 mb-1 layoutModal layoutModalSuccess"
                  >
                    <svg-icon
                      type="mdi"
                      size="70"
                      :path="mdiContentSaveCheckOutline"
                    />
                  </span>
                  <span
                    class="d-flex justify-content-center px-2 my-1 fixLayoutTextModal"
                  >
                    Modelo Exportado com Sucesso!
                  </span>
                  <span
                    class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
                  >
                    <b-button
                      id="hide-btn"
                      class="modal-btn-fix"
                      variant="primary"
                      @click="closeModalExportSuccess()"
                    >
                      Ok
                    </b-button>
                  </span>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiFolderOpenOutline,
  mdiContentSaveAlertOutline,
  mdiContentSaveCheckOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import BiddingTable from '@/views/bidding/components/BiddingTable.vue'
import BiddingKanban from '@/views/bidding/components/BiddingKanban.vue'
import BiddingFilter from '@/views/bidding/components/BiddingFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    SvgIcon,
    SpinLoader,
    BiddingTable,
    BiddingKanban,
    BiddingFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const exportPercentage = '0 %'
    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')
    syncLoad.addTrigger('kanban')
    syncLoad.addTrigger('filter')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'imported',
        label: 'Importada',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'client__name',
        label: 'Órgão / Instituição',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'platform__name',
        label: 'Site',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'date',
        label: 'Data da Disputa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'bidding_hour',
        label: 'Hora da Disputa',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'date_proposal',
        label: 'Data da Proposta',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'hour_proposal',
        label: 'Hora da Proposta',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'trade_number',
        label: 'Nº Pregão',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'uasg',
        label: 'UASG',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'type__name',
        label: 'Tipo',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'company__corporate_name',
        label: 'Empresa',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'owner__get_full_name',
        label: 'Responsável',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'modality__name',
        label: 'Modalidade',
        visible: false,
        fixedColumn: false,
      },
      {
        key: 'phase__name',
        label: 'Fase',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'status__name',
        label: 'Status',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'interest__name',
        label: 'Interesse',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'is_homologated',
        label: 'Homologação',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'actions',
        label: '',
        visible: true,
        fixedColumn: true,
      },
    ]

    const viewModeTable = 'viewModeTable'
    const viewModeKanban = 'viewModeKanban'
    const viewMode = viewModeTable

    return {
      exportPercentage,
      isLoading,
      filter,
      tableColumns,
      syncLoad,
      viewModeTable,
      viewModeKanban,
      viewMode,
    }
  },
  computed: {
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
  },
  methods: {
    updateColumns(val) {
      this.$refs.refBiddingTable.refreshColumns(val)
    },
    updateFilter(val) {
      this.filter = val
    },
    updateProgress(val) {
      this.exportPercentage = val
    },
    resetProgress() {
      this.exportPercentage = '0 %'
    },
    onExportFilter() {
      this.$refs.refBiddingTable.exportBiddingsCSV()
    },
    openModalExportSpinner() {
      this.$refs.modalExportSpinner.show()
    },
    closeModalExportSpinner() {
      this.$refs.modalExportSpinner.hide()
    },
    openModalExportSuccess() {
      this.$refs.modalExportSuccess.show()
    },
    closeModalExportSuccess() {
      this.$refs.modalExportSuccess.hide()
    },
    openModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.show()
    },
    closeModalExportUnsuccess() {
      this.$refs.modalExportUnsuccess.hide()
    },
    updateViewMode(val) {
      this.viewMode = val
    },
  },
  setup() {
    return {
      mdiPlus,
      mdiFolderOpenOutline,
      mdiContentSaveAlertOutline,
      mdiContentSaveCheckOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
#addBiddingBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}

#addBiddingBtn img{
  width: 2.0rem;
  height: 2.0rem;
  filter: invert(100) contrast(100) brightness(1);
}

#modalPercentage {
  font-size: 2.4rem;
  font-weight: 600;
  color: #6e6b7b;
}

</style>
