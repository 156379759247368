<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="status"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Status"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="status"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="options"
          :placeholder="'Status'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #selected-option="{ name, color }">
            <b-badge
              pill
              class="text-capitalize"
              :style="{'background-color': color}"
            >
              {{ name | truncate(18, '...') }}
            </b-badge>
          </template>

          <template #no-options>
            Não há opções de status.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
  BBadge,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'

export default {
  components: {
    BFormGroup,
    BBadge,
    ValidationProvider,
    vSelect,
  },
  filters: {
    truncate,
  },
  model: {
    prop: 'statusValue',
    event: 'statusChange',
  },
  props: {
    statusValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterPhase: {
      type: [Object, String],
      required: false,
      default: null,
    },
  },
  data() {
    const dataOptions = []
    const options = []

    return {
      dataOptions,
      options,
      required,
    }
  },
  computed: {
    status: {
      get() {
        return this.statusValue
      },
      set(value) {
        this.$emit('statusChange', value)
      },
    },
  },
  watch: {
    filterPhase: function updateByPhase(obj) {
      this.onCreate()

      if (obj) {
        if ('id' in obj) {
          if (this.status) {
            if (this.status.phase !== obj.id) {
              this.status = {
                id: '',
                name: '',
                color: '',
                phase: '',
                phase_set: {
                  name: '',
                  color: '',
                },
              }
              this.status = null
            }
          }
        }
      } else {
        this.status = null
      }
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    updateOptions() {
      const optionsStatus = []

      if (this.filterPhase) {
        this.dataOptions.forEach(obj => {
          if (this.filterPhase.id !== '' && this.filterPhase.id === obj.phase) {
            optionsStatus.push(obj)
          }
        })
      }

      this.options = optionsStatus
    },
    onCreate() {
      syslic
        .bidding
        .status
        .fetchAllStatusBasic()
        .then(response => {
          this.dataOptions = response.data.results
          this.updateOptions()

          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>
