<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        class="position-relative biddingTableView"
        primary-key="id"
        responsive
        show-empty
        striped
        hover
        empty-text="Não há licitações cadastradas."
        :items="data"
        :fields="visibleFields"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        :busy="isLoading"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando...</strong>
          </div>
        </template>
        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'bidding-view', params: { id: field.item.id } } "
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(is_homologated)="field">
          <div class="d-flex">
            <div
              v-if="field.item.is_filed"
              class="d-flex justify-content-center"
            >
              <svg-icon
                type="mdi"
                :path="mdiArchiveArrowUpOutline"
              />
            </div>
            <div class="d-flex justify-content-center">
              <svg-icon
                type="mdi"
                :class="field.item.is_homologated ? 'colorHomologated' : ''"
                :path="mdiFileCertificateOutline"
              />
            </div>
          </div>
        </template>

        <template #cell(imported)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.imported ? 'SIM' : 'NÃO' }}
          </div>
        </template>

        <template #cell(client__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.client_set.name }}
          </div>
        </template>

        <template #cell(platform__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.platform_set.name }}
          </div>
        </template>

        <template #cell(date)="field">
          <div
            class="d-block text-nowrap"
          >
            <template v-if="field.item.date">
              {{ moment(field.item.date).format('DD/MM/YYYY') }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <template #cell(bidding_hour)="field">
          <div
            class="d-block text-nowrap"
          >
            <template v-if="field.item.bidding_hour">
              {{ field.item.bidding_hour }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <template #cell(date_proposal)="field">
          <div
            class="d-block text-nowrap"
          >
            <template v-if="field.item.date_proposal">
              {{ moment(field.item.date_proposal).format('DD/MM/YYYY') }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <template #cell(trade_number)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.trade_number }}
          </div>
        </template>

        <template #cell(uasg)="field">
          <div
            class="d-block text-nowrap font-weight-bolder"
          >
            {{ field.item.uasg }}
          </div>
        </template>

        <template #cell(type__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.type_set.name }}
          </div>
        </template>

        <template #cell(company__corporate_name)="field">
          <div
            class="d-block text-nowrap"
          >
            <template v-if="field.item.company_set">
              {{ field.item.company_set.corporate_name }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <template #cell(owner__get_full_name)="field">
          <div>
            <template v-if="field.item.owner_set">
              <b-media>
                <template #aside>
                  <b-avatar
                    :src="userImages[field.item.owner_set.get_uuid]"
                    size="2.5rem"
                  />
                </template>
                <div
                  class="font-weight-bold text-nowrap biddingOwnerField"
                >
                  {{ field.item.owner_set.first_name }}
                </div>
              </b-media>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>

        <template #cell(modality__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.modality_set.name }}
          </div>
        </template>

        <template #cell(phase__name)="field">
          <div
            class="d-block text-nowrap"
          >
            {{ field.item.phase_set.name }}
          </div>
        </template>

        <template #cell(status__name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.status_set.color}"
            >
              {{ field.item.status_set.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(interest__name)="field">
          <div
            class="d-block text-nowrap"
          >
            <b-badge
              pill
              class="text-capitalize sizeMin px-1 d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': field.item.interest_set.color}"
            >
              {{ field.item.interest_set.name }}
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="field">
          <div
            :class="totalItems > 1 ? '' : 'dropdownfix'"
          >
            <b-dropdown
              variant="link"
              no-caret
              :dropup="true"
              :boundary="'scrollParent'"
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'bidding-view', params: { id: field.item.id } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Detalhes</span>
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="!hasPermissionToDelete"
                @click="showConfirmFiledBidModal(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="16"
                  :path="field.item.is_filed ? mdiArchiveArrowUpOutline : mdiArchiveArrowDownOutline"
                />
                <span
                  v-if="!field.item.is_filed"
                  class="align-middle ml-50"
                >
                  Arquivar
                </span>
                <span
                  v-else
                  class="align-middle ml-50"
                >
                  Desarquivar
                </span>
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="!hasPermissionToUpdate"
                :to="{ name: 'bidding-edit', params: { id: field.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                :disabled="!hasPermissionToDelete"
                @click="showConfirmDeleteBidModal(field.item)"
              >
                <svg-icon
                  type="mdi"
                  size="16"
                  :path="mdiTrashCanOutline"
                />
                <span class="align-middle ml-50">Remover</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
      <b-modal
        id="confirmDeleteBidModal"
        ref="confirmDeleteBidModal"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :path="mdiAlertCircle"
                />
              </span>
              <span
                class="d-flex justify-content-center fixLayoutTitleModal"
              >
                Atenção!
              </span>
              <span
                class="d-flex justify-content-center my-1 fixLayoutTextModal"
              >
                Você realmente quer remover essa licitação? <br> Essa ação NÃO pode ser desfeita!
              </span>
              <span
                class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
              >
                <b-button
                  id="hide-btn"
                  variant="primary"
                  class="mx-50"
                  style="min-width: 112px;"
                  @click="removeBid()"
                >
                  Sim
                </b-button>
                <b-button
                  id="hide-btn"
                  variant="outline-primary"
                  class="mx-50"
                  style="min-width: 112px;"
                  @click="closeConfirmDeleteBidModal()"
                >
                  Não
                </b-button>
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        id="confirmFiledBidModal"
        ref="confirmFiledBidModal"
        class="modalStyle"
        no-close-on-backdrop
        no-close-on-esc
        centered
        hide-footer
      >
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col>
              <span
                class="d-flex justify-content-center px-2 mb-1 layoutModalAlert"
              >
                <svg-icon
                  type="mdi"
                  size="70"
                  :path="selectedBid.is_filed ? mdiArchiveArrowUpOutline : mdiArchiveArrowDownOutline"
                />
              </span>
              <span
                class="d-flex justify-content-center fixLayoutTitleModal"
              >
                Atenção!
              </span>
              <span
                v-if="selectedBid.is_filed"
                class="d-flex justify-content-center my-1 fixLayoutTextModal"
              >
                Você realmente deseja desarquivar essa licitação?
              </span>
              <span
                v-else
                class="d-flex justify-content-center my-1 fixLayoutTextModal"
              >
                Você realmente deseja arquivar essa licitação?
              </span>
              <span
                class="d-flex justify-content-center px-2 mt-3 mb-2 fixLayoutTextModal"
              >
                <b-button
                  id="hide-btn"
                  variant="primary"
                  class="mx-50"
                  style="min-width: 112px;"
                  @click="filedBid()"
                >
                  Sim
                </b-button>
                <b-button
                  id="hide-btn"
                  variant="outline-primary"
                  class="mx-50"
                  style="min-width: 112px;"
                  @click="closeConfirmFiledBidModal()"
                >
                  Não
                </b-button>
              </span>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BButton,
  BModal,
  BForm,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAvatar,
  BBadge,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiTableEdit,
  mdiFileCertificateOutline,
  mdiAlertCircle,
  mdiTrashCanOutline,
  mdiArchiveArrowDownOutline,
  mdiArchiveArrowUpOutline,
} from '@mdi/js'

import exportFromJSON from 'export-from-json'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BButton,
    BModal,
    BForm,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatar,
    BBadge,
    BSpinner,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const totalPages = 0
    const currentPage = 1
    const selectedBid = {
      is_filed: false,
    }

    const sortOptions = {
      sortBy: 'date',
      isSortDirDesc: false,
    }

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap',
      },
      {
        key: 'is_homologated',
        label: '',
        sortable: false,
        visible: true,
        fixedColumn: false,
        thClass: 'biddingTableColumnHomologate',
      },
      {
        key: 'imported',
        label: 'Importada',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'client__name',
        label: 'Órgão / Instituição',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'platform__name',
        label: 'Site',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'date',
        label: 'Data da Disputa',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'bidding_hour',
        label: 'Hora da Disputa',
        sortable: true,
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'date_proposal',
        label: 'Data da Proposta',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'hour_proposal',
        label: 'Hora da Proposta',
        sortable: true,
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'trade_number',
        label: 'Nº Pregão',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'uasg',
        label: 'UASG',
        sortable: true,
        tdClass: '',
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'type__name',
        label: 'Tipo',
        sortable: true,
        tdClass: '',
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'company__corporate_name',
        label: 'Empresa',
        sortable: true,
        tdClass: '',
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'owner__get_full_name',
        label: 'Responsável',
        sortable: true,
        tdClass: '',
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'modality__name',
        label: 'Modalidade',
        sortable: true,
        tdClass: '',
        visible: false,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'phase__name',
        label: 'Fase',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'status__name',
        label: 'Status',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'interest__name',
        label: 'Interesse',
        sortable: true,
        tdClass: '',
        visible: true,
        fixedColumn: false,
        thClass: 'text-nowrap biddingTableColumn',
      },
      {
        key: 'actions',
        label: '',
        sortable: false,
        visible: true,
        fixedColumn: true,
        thClass: 'text-nowrap biddingTableAction',
        tdClass: 'biddingTableAction',
      },
    ]

    const data = []
    const userImages = []
    const lastSearch = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('bidding')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    const meta = new MetaData('BiddingTable')

    return {
      totalItems,
      totalPages,
      currentPage,
      tableColumns,
      data,
      userImages,
      lastSearch,
      syncLoad,
      isLoading,
      fetchId,
      selectedBid,
      sortOptions,
      meta,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
    ajustableFields() {
      const fields = []
      this.tableColumns.forEach((element, index) => {
        if (!element.fixedColumn) {
          fields.push({
            index,
            label: element.label,
          })
        }
      })

      return fields
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }

        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, i) => {
          if (obj.key === element.key) {
            this.tableColumns[i].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const filterBidding = this.filter

      filterBidding.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        filterBidding.ordering = `-${this.sortOptions.sortBy}`
      } else {
        filterBidding.ordering = this.sortOptions.sortBy
      }
      syslic
        .bidding
        .fetchBiddingList(filterBidding)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.totalPages = Math.ceil(response.data.count / this.filter.page_size)
            this.data = response.data.results
            promise = this.loadUsersPhotos()
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('bidding')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados das licitações.',
                text: 'Não foi possível ler os dados das licitações do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('bidding')
          }
        })
    },
    loadUsersPhotos() {
      const users = []
      this.data.forEach(element => {
        if (element.owner_set) {
          users.push(element.owner_set.get_uuid)
        }
      })

      const promiseList = []
      users.forEach(user => {
        if (!(user in this.userImages)) {
          const promise = new Promise((resolve, reject) => {
            syslic.user.getUserImage(user)
              .then(response => {
                this.userImages[user] = response
                resolve()
              })
              .catch(() => {
                reject()
              })
          })

          promiseList.push(promise)
        }
      })

      return Promise.allSettled(promiseList)
    },
    deleteBidding(item) {
      syslic
        .bidding
        .deleteBidding(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Licitação removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(error => {
          let textError = 'Não foi possível remover a licitação do servidor, por favor entre em contato com o administrador do sistema.'
          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta licitação é referenciada em algum contrato, por este motivo não será possível remove-la.'
            }
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onRowSelected(item) {
      router.push({ name: 'bidding-view', params: { id: item.id } }).catch(() => {})
    },
    showConfirmDeleteBidModal(item) {
      this.selectedBid = item
      this.$refs.confirmDeleteBidModal.show()
    },
    closeConfirmDeleteBidModal() {
      this.$refs.confirmDeleteBidModal.hide()
    },
    removeBid() {
      this.deleteBidding(this.selectedBid)
      this.selectedBid = null
      this.closeConfirmDeleteBidModal()
    },
    showConfirmFiledBidModal(item) {
      this.selectedBid = item
      this.$refs.confirmFiledBidModal.show()
    },
    closeConfirmFiledBidModal() {
      this.$refs.confirmFiledBidModal.hide()
    },
    filedBid() {
      const dataValue = {
        is_filed: !this.selectedBid.is_filed,
      }
      syslic
        .bidding
        .toFileBidding(this.selectedBid.id, dataValue)
        .then(() => {
          this.closeConfirmFiledBidModal()
          const text = this.selectedBid.is_filed ? 'desarquivada' : 'arquivada'

          this.toast({
            component: ToastificationContent,
            props: {
              title: `Licitação ${text} com sucesso.`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          this.closeConfirmFiledBidModal()
          const text = this.selectedBid.is_filed ? 'desarquivar' : 'arquivar'

          const textError = `Não foi possível ${text} a licitação do servidor, por favor entre em contato com o administrador do sistema.`
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    fetchBiddingsPage(page) {
      const biddingFilter = this.filter
      biddingFilter.p = page
      return new Promise((resolve, reject) => {
        syslic
          .bidding
          .fetchBiddings(biddingFilter)
          .then(response => {
            resolve(response.data.results)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async recursiveBiddingsFetch({ page, data }) {
      const biddingsPage = await this.fetchBiddingsPage(page)

      const fetchProgress = `${Math.ceil((page / this.totalPages) * 100)} %`

      data.push(...biddingsPage)
      this.$emit('updateExportProgress', fetchProgress)

      if (page < this.totalPages) {
        return this.recursiveBiddingsFetch({ page: page + 1, data })
      }
      return data
    },
    exportBiddingsCSV() {
      if (!(this.data.length > 0)) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Não há licitações para exportar.',
            text: 'Não há licitações para exportar, por favor adicione alguma ou altere o seu filtro.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
            timeout: false,
          },
        })
        return
      }

      this.$emit('openLoading')

      const dataExport = []

      this.recursiveBiddingsFetch({ page: 1, data: [] })
        .then(response => {
          response.forEach(bidding => {
            const biddingItem = {
              ID: bidding.id,
              Homologada: bidding.is_homologated ? 'Sim' : 'Não',
              'Órgão / Instituição': bidding.client_set.name || bidding.client_set.name_fantasy,
              Plataforma: bidding.platform_set.name,
              'Data da Disputa': '',
              'Hora da Disputa': '',
              'Data da Proposta': '',
              'Hora da Proposta': '',
              'Nº Pregão': `${bidding.trade_number}`,
              UASG: `${bidding.uasg}`,
              Tipo: bidding.type_set.name,
              Empresa: '',
              Responsavel: '',
              Modalidade: bidding.modality_set.name,
              Fase: bidding.phase ? bidding.phase_set.name : '',
              Status: '',
              Interesse: '',
            }

            biddingItem['Data da Disputa'] = bidding.date ? moment(bidding.date).format('DD/MM/YYYY') : ''
            biddingItem['Hora da Disputa'] = bidding.bidding_hour ? bidding.bidding_hour : ''
            biddingItem['Data da Proposta'] = bidding.date_proposal ? moment(bidding.date_proposal).format('DD/MM/YYYY') : ''
            biddingItem['Hora da Proposta'] = bidding.hour_proposal ? bidding.hour_proposal : ''
            biddingItem.Empresa = bidding.company_set ? bidding.company_set.corporate_name : ''
            biddingItem.Responsavel = bidding.owner_set ? bidding.owner_set.get_full_name : ''
            biddingItem.Status = bidding.status_set ? bidding.status_set.name : ''
            biddingItem.Interesse = bidding.interest_set ? bidding.interest_set.name : ''

            dataExport.push(biddingItem)
          })

          const fileName = 'exportação_licitações'
          const exportType = exportFromJSON.types.csv

          exportFromJSON({ data: dataExport, fileName, exportType })

          setTimeout(() => {
            this.$emit('closeLoading')
            this.$emit('openSuccess')
            this.$emit('finishExport')
          }, 1000)
        })
        .catch(() => {
          this.$emit('closeLoading')
          this.$emit('openError')
          this.$emit('finishExport')
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiTableEdit,
      mdiFileCertificateOutline,
      mdiAlertCircle,
      mdiTrashCanOutline,
      mdiArchiveArrowDownOutline,
      mdiArchiveArrowUpOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.biddingTableColumnHomologate {
  max-width: 10px !important;
}

.biddingTableColumn {
  min-width: 100px;
}

.biddingTableAction {
  max-width: 5rem;
}

.biddingTableAction .dropdown-toggle {
  padding-left: 0px;
}

.biddingOwnerField {
  margin-top: 0.5rem;
}

.biddingTableView {
  border-radius: 5px;
}

.colorHomologated {
  color: $success_2;
}

.modalStyle .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;

  justify-content: center;

  padding-bottom: 40px;
}

.fixLayoutTitleModal {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.sizeMin {
  min-width: 110px;
}
</style>
