<template>
  <div>
    <b-row
      v-if="isLoading"
    >
      <b-col>
        <div class="text-center my-3">
          <b-spinner
            variant="info"
            class="align-middle mb-1"
          />
          <br>
          <strong class="pt-2">Carregando...</strong>
        </div>
      </b-col>
    </b-row>
    <perfect-scrollbar
      v-else
    >
      <b-row>
        <b-col
          class="d-flex align-items-top justify-content-start"
        >
          <b-card
            v-for="(column, index) in painel"
            :key="index"
            class="kanbanColumn p-2 mr-2"
            no-body
          >
            <h3 class="kanbanColumnTitle mb-1">
              {{ column.name }}
            </h3>
            <draggable
              class="h-100"
              :list="column.biddings"
              group="people"
              @change="cardChange"
            >
              <b-card
                v-for="element in column.biddings"
                :key="element.id"
                class="mb-1 p-1 kanbanCard"
                no-body
                :style="`border-left: 0.8rem solid ${element.interest_set.color}  !important;`"
                @click="selectBidding(element)"
              >
                <b-row>
                  <b-col class="mb-50">
                    <span class="kanbanCardTitle">{{ element.client_set.name | truncate(28, '...') }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="d-flex align-items-center"
                  >
                    <svg-icon
                      class="kanbanColumnIcon"
                      type="mdi"
                      size="22"
                      :path="mdiCalendarMonth"
                    />
                    <span class="kanbanCardItem mr-50">Data: </span>
                    <span class="kanbanCardContent">{{ moment(element.date).format('DD/MM/YYYY') }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="d-flex align-items-center"
                  >
                    <svg-icon
                      class="kanbanColumnIcon"
                      type="mdi"
                      size="22"
                      :path="mdiTicketConfirmationOutline"
                    />
                    <span class="kanbanCardItem mr-50">Nº Pregão:</span>
                    <span class="kanbanCardContent"> {{ element.trade_number }}</span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="d-flex align-items-end"
                  >
                    <svg-icon
                      class="kanbanColumnIcon"
                      type="mdi"
                      size="22"
                      :path="mdiDomain"
                    />
                    <span class="kanbanCardItem">{{ element.platform_set.name }}</span>
                  </b-col>
                </b-row>
                <b-row class="mt-50">
                  <b-col
                    cols="8"
                  >
                    <span class="kanbanCardItemValue">Valor: </span>
                    <span class="kanbanCardContentValue">R$ {{ getPrice(element) }}</span>
                  </b-col>
                  <b-col
                    cols="4"
                    class="d-flex justify-content-end"
                  >
                    <b-badge
                      pill
                      class="text-capitalize"
                      :style="{'background-color': element.interest_set.color}"
                    >
                      {{ element.interest_set.name }}
                    </b-badge>
                  </b-col>
                </b-row>
              </b-card>
            </draggable>
          </b-card>
        </b-col>
      </b-row>
    </perfect-scrollbar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BCard,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiCalendarMonth,
  mdiDomain,
  mdiTicketConfirmationOutline,
} from '@mdi/js'

import draggable from 'vuedraggable'
import moment from 'moment'
import SvgIcon from '@jamescoyle/vue-icon'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import router from '@/router'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import SyncLoad from '@/components/utils/syncLoad'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BCard,
    BSpinner,
    draggable,
    SvgIcon,
    PerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['ready'],
  data() {
    const painel = [
      {
        name: 'Aguardando Cadastro',
        color: '#555',
        biddings: [
          {
            id: 1,
            uasg: '1221214',
            trade_number: '51554',
            client_set: {
              name: 'Prefeitura de Paraguaçu Paulista',
            },
            platform_set: {
              name: 'Compras.net',
            },
            interest_set: {
              name: 'Alto',
              color: '#444',
            },
          },
          {
            id: 3,
            uasg: '661221214',
            trade_number: '6651554',
            client_set: {
              name: 'Prefeitura de Campinas',
            },
            platform_set: {
              name: 'Compras.net',
            },
            interest_set: {
              name: 'Alto',
              color: '#444',
            },
          },
        ],
      },
      {
        name: 'Em Revisão',
        color: '#555',
        biddings: [
          {
            id: 2,
            uasg: '1221214',
            trade_number: '51554',
            client_set: {
              name: 'Prefeitura de Piracicaba',
            },
            platform_set: {
              name: 'Compras.net',
            },
            interest_set: {
              name: 'Alto',
              color: '#444',
            },
          },
        ],
      },
    ]
    const statusList = []
    const data = []

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('bidding')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const fetchId = ''

    return {
      painel,
      statusList,
      data,
      syncLoad,
      isLoading,
      fetchId,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding.can_delete
    },
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        if ((val.data_start === '')
          || (val.date_finish === '')
          || (val.phase === '')
        ) {
          if (this.active) {
            this.$swal({
              title: 'Atenção!',
              text: 'Para visualizar as licitações no modo Kanban, é necessário sempre selecionar a fase, a data inícial e a data final no filtro.',
              icon: 'warning',
              showConfirmButton: false,
            })
          }
        }

        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      this.isLoading = true

      const filterBidding = this.filter
      filterBidding.ordering = '-date'

      syslic
        .bidding
        .fetchBiddingList(filterBidding)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.data = response.data.results
            promise = syslic.bidding.status.fetchAllStatusBasic({ phase: this.filter.phase })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }
          return promise
        })
        .then(response => {
          this.statusList = response.data.results
          this.generatePainel()
          this.syncLoad.ready('bidding')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados das licitações.',
                text: 'Não foi possível ler os dados das licitações do servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.syncLoad.ready('bidding')
          }
        })
    },
    generatePainel() {
      const painel = []

      this.statusList.forEach(status => {
        const column = status
        const biddings = []
        this.data.forEach(bidding => {
          if (bidding.status === column.id) {
            biddings.push(bidding)
          }
        })
        column.biddings = biddings
        painel.push(column)
      })

      this.painel = painel
    },
    deleteBidding(item) {
      syslic
        .bidding
        .deleteBidding(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Licitação removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.data.splice(this.data.indexOf(item), 1)
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover licitação.',
              text: 'Não foi possível remover a licitação do servidor, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você quer remover a licitação.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteBidding(item)
          }
        })
    },
    cardChange(ev) {
      if ('added' in ev) {
        this.painel.forEach((column, painelIndex) => {
          column.biddings.forEach((el, index) => {
            if (el.id === ev.added.element.id) {
              const newBidding = JSON.parse(JSON.stringify(this.painel[painelIndex].biddings[index]))
              newBidding.status = column.id

              this.statusList.forEach(statusObj => {
                if (statusObj.id === column.id) {
                  newBidding.status_set = statusObj

                  syslic
                    .bidding
                    .updateBidding(newBidding.id, newBidding)
                    .then(() => {
                      this.toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Licitação atualizada com sucesso.',
                          icon: 'EditIcon',
                          variant: 'success',
                        },
                      })

                      this.painel[painelIndex].biddings[index] = newBidding
                    })
                    .catch(() => {
                      this.toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Falha ao atualizar licitação.',
                          text: 'Não foi possível remover a licitação do servidor, por favor entre em contato com o administrador do sistema.',
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                          timeout: false,
                        },
                      })
                    })
                }
              })
            }
          })
        })
      }

      this.$forceUpdate()
    },
    getPrice() {
      return '0,00'
    },
    selectBidding(item) {
      router.push({ name: 'bidding-view', params: { id: item.id } })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiCalendarMonth,
      mdiDomain,
      mdiTicketConfirmationOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/base/core/colors/palette-variables.scss";

.kanbanColumn {
  background-color: #e2e2e5;
  width: 26rem !important;
  min-width: 26rem !important;
  min-height: 50rem;
}

.kanbanColumnTitle {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1.1rem;
  color: #5E5873;
}

.kanbanCardTitle {
  font-weight: 800;
  font-size: 1.125rem;
  color: #5E5873;
}

.kanbanCardItem {
  font-weight: 500;
  color: #5E5873;
  font-size: 0.975rem;
}

.kanbanCardContent {
  font-size: 0.975rem;
}

.kanbanColumnIcon {
  color: $primary;
  margin-right: 0.5rem;
}

.kanbanCardItemValue {
  font-weight: 400;
  color: #5E5873;
  font-size: 1.1rem;
}

.kanbanCardContentValue {
  font-size: 1.1rem;
}

.kanbanCard:hover {
  box-shadow: 0 0 20px rgba(33,33,33,.2) !important;
}

.ps {
  height: 48rem;
  width: 100%;
}
</style>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
