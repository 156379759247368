var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":"bidding_is_filed","rules":{
    required: _vm.requiredField,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.labelName,"label-for":"bidding_is_filed","label-class":_vm.labelClass}},[_c('v-select',{class:errors.length > 0 ? 'error-select' : '',attrs:{"id":"bidding_is_filed","options":_vm.filedStatusOptions,"placeholder":'Licitação Arquivada',"disabled":_vm.readonly,"clearable":!_vm.readonly},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
  var label = ref.label;
return [_c('span',[_vm._v(_vm._s(label))])]}}],null,true),model:{value:(_vm.filedStatus),callback:function ($$v) {_vm.filedStatus=$$v},expression:"filedStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }