<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="platform"
      :rules="requiredField ? 'required' : ''"
    >
      <b-form-group
        label="Plataforma"
        label-for="platform"
      >
        <v-select
          id="platform"
          v-model="platform"
          :class="errors.length > 0 ? 'error-select' : ''"
          :filter="fuseSearch"
          label="name"
          :options="dataOptions"
          :placeholder="'Plataforma'"
          :disabled="readonly"
          :clearable="!readonly"
          @open="onCreate()"
        >
          <template #option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #selected-option="{ name }">
            <span>{{ name }} </span>
          </template>

          <template #no-options>
            Não há opções de plataformas.
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'

import {
  ValidationProvider,
} from 'vee-validate'

import {
  required,
} from '@validations'

import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import syslic from '@/syslic'

export default {
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  model: {
    prop: 'platformValue',
    event: 'platformChange',
  },
  props: {
    platformValue: {
      type: [Object, String],
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredField: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const dataOptions = []

    return {
      dataOptions,
      required,
    }
  },
  computed: {
    platform: {
      get() {
        return this.platformValue
      },
      set(value) {
        this.$emit('platformChange', value)
      },
    },
  },
  created() {
    this.onCreate()
  },
  methods: {
    onCreate() {
      syslic
        .bidding
        .platform
        .fetchPlatformList()
        .then(response => {
          this.dataOptions = response.data.results
          this.$emit('ready', true)
        })
        .catch(() => {
          this.$emit('ready', true)
        })
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name'],
        shouldSort: true,
      })
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list
    },
  },
}
</script>
<style lang="scss" scoped>
.v-select {
  background-color: #fff;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
